import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import Formsy from 'formsy-react'
import { ParcelQuotationFormProps, ParcelQuotationFormState, ParcelQuotationModel } from './types'
import medidasImg from 'img/medidas-caja.jpg'
import { AButton, Go } from 'shared/components/common'
import { SelectFormsy, InputFormsy, CheckboxFormsy } from 'shared/components/formsy'

import classNames from 'classnames'
import AutocompleteFormsy from 'shared/components/formsy/AutocompleteFormsy/AutocompleteFormsy'
import { ApiService } from 'core/services'

export class ParcelQuotationForm extends Component<ParcelQuotationFormProps, ParcelQuotationFormState> {
  destinationSelect: any
  form: any
  state: ParcelQuotationFormState = {}
  onSubmit = (model: ParcelQuotationModel) => {
    if (this.props.onConfirm) {
      const parsed = this.parseModel(model)
      this.props.onConfirm(parsed)
    }
    this.form.reset()
  }

  componentDidMount() {
    if (this.props.terminals.length) {
      this.setState({
        formattedTerminals: [...this.formatTerminals(this.props.terminals)]
      })
    }
    this.getPostalCodes()
  }

  parseModel = (model: any): ParcelQuotationModel => {
    const { terminals, packageTypes, rad, ead } = this.props
    let radVal = 0
    let eadVal = 0
    model.rad ? (radVal = rad.amount) : (radVal = 0)
    model.ead ? (eadVal = ead.amount) : (eadVal = 0)
    const origin = terminals.findIndex(t => t.id === parseInt(model.origin, 10))
    const destination = terminals.findIndex(t => t.id === parseInt(model.destination, 10))
    const packageType = packageTypes.findIndex(p => p.id === parseInt(model.packageType, 10))
    if (origin === -1 || destination === -1 || packageType === -1) {
      throw 'Something went wrong on parse form'
    }

    return {
      origin: { ...terminals[origin] },
      destination: { ...terminals[destination] },
      packageType: { ...packageTypes[packageType] },
      weight: parseFloat(model.weight),
      length: parseFloat(model.length),
      width: parseFloat(model.width),
      height: parseFloat(model.height),
      value: parseFloat(model.value),
      ead: eadVal,
      rad: radVal
    }
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  handleOriginChange = (val: any) => {
    let id = val
    if (id) {
      id = parseInt(val, 10)
    } else {
      this.destinationSelect.resetValue()
    }
    this.setState({ selectedOrigin: id })
  }

  handleDestinationChange = (val: any) => {
    let id = val
    if (id) {
      id = parseInt(val, 10)
    }
    this.setState({ selectedDestination: id })
  }

  getPostalCodes = () => {
    ApiService.post<any, any>('parcels/searchValidCodes', {}).then((postalCodes: any[]) => {
      const formattedPostalCodes = this.formatPostalCodes(postalCodes)
      this.setState({
        postalCodeList: formattedPostalCodes
      })
    })
  }

  formatPostalCodes = (array: any[]) => {
    return array.map((element: any) => {
      return { ...element, zip_code: element.zip_code.toString(), name: element.name === 'Los Mochis Leyva' ? 'Los Mochis' : element.name }
    })
  }

  handleOriginZipcode = (val: any) => {
    if (val.length === 5) {
      const obj = this.state.postalCodeList?.find(el => val === el.zip_code)
      if (obj.name === 'Los Mochis') {
        const terminal = this.state.formattedTerminals?.find(el => el.name === 'Los Mochis')
        this.handleOriginChange(terminal.id)
      } else {
        this.handleOriginChange(obj.branchoffice_id)
      }
    }
  }

  handleDestinationZipcode = (val: any) => {
    if (val.length === 5) {
      const obj = this.state.postalCodeList?.find(el => val === el.zip_code)
      if (obj.name === 'Los Mochis') {
        const terminal = this.state.formattedTerminals?.find(el => el.name === 'Los Mochis')
        this.handleDestinationChange(terminal.id)
      } else {
        this.handleDestinationChange(obj.branchoffice_id)
      }
    }
  }

  formatTerminals = (terminals: any[]) => {
    const filteredArr = terminals.filter(el => el.prefix !== 'LMM01')
    const arrIndex = filteredArr.findIndex(el => el.prefix === 'LMM2')
    if (arrIndex !== -1) {
      filteredArr[arrIndex].name = 'Los Mochis'
    }
    return filteredArr
  }

  render() {
    const { disabled, packageTypes } = this.props
    const { canSubmit, selectedOrigin, postalCodeList, selectedDestination, formattedTerminals } = this.state
    return (
      <Formsy
        ref={(node: any) => {
          this.form = node
        }}
        onValidSubmit={this.onSubmit}
        onValid={this.onValidForm}
        onInvalid={this.onInvalidForm}
      >
        <Row>
          <Col sm={6}>
            <SelectFormsy
              name="origin"
              label="Origen"
              value={selectedOrigin}
              onChange={this.handleOriginChange}
              selectProps={{ disabled, className: 'custom-select2' }}
              labelClassName="h5"
              required
            >
              <option value="">Selecciona tu origen</option>
              {(formattedTerminals &&
                formattedTerminals.map(t => {
                  const excludeTerminals = ['LMM02', 'GML01', 'CUL02', 'GDL02']
                  if (!excludeTerminals.includes(t.prefix)) {
                    return (
                      <option value={t.id} key={t.id}>
                        {t.name}
                      </option>
                    )
                  }
                })) ||
                null}
            </SelectFormsy>
            <SelectFormsy
              name="destination"
              label="Destino"
              value={selectedDestination}
              ref={(node: any) => {
                this.destinationSelect = node
              }}
              selectProps={{ className: 'custom-select2', disabled: !selectedOrigin || disabled }}
              labelClassName="h5"
              required
            >
              <option value="">Selecciona tu destino</option>
              {(formattedTerminals &&
                formattedTerminals.map(t => {
                  const excludeTerminals = ['LMM02', 'GML01', 'CUL02', 'GDL02']
                  if (!excludeTerminals.includes(t.prefix)) {
                    return (
                      <option value={t.id} key={t.id} className={classNames({ 'd-none': t.id === selectedOrigin })}>
                        {t.name}
                      </option>
                    )
                  }
                })) ||
                null}
            </SelectFormsy>
            <Row className="medidas">
              <Col sm={3} xs={4} className="d-flex flex-row align-items-center">
                <InputFormsy
                  label="Largo"
                  labelClassName="h6"
                  inputProps={{
                    type: 'number',
                    min: 1,
                    placeholder: '0'
                  }}
                  name="length"
                  required
                />
                <span>&nbsp;CM</span>
              </Col>

              <Col sm={3} xs={4} className="d-flex flex-row align-items-center">
                <InputFormsy
                  label="Ancho"
                  labelClassName="h6"
                  inputProps={{
                    type: 'number',
                    min: 1,
                    placeholder: '0'
                  }}
                  name="width"
                  required
                />
                <span>&nbsp;CM</span>
              </Col>
              <Col sm={3} xs={4} className="d-flex flex-row align-items-center">
                <InputFormsy
                  label="Alto"
                  labelClassName="h6"
                  inputProps={{
                    type: 'number',
                    min: 1,
                    placeholder: '0'
                  }}
                  name="height"
                  required
                />
                <span>&nbsp;CM</span>
              </Col>
              <Col className="d-sm-block d-none" sm={3} xs={3} style={{ zIndex: 999999999 }}>
                <div className="wrapper3">
                  <div className="tooltip3">
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <img src={medidasImg} className="img-responsive" style={{ width: '105%' }} />
                        <h5 className="big-pink-op">Medidas máximas</h5>
                        <h6 className="big-blue">LARGO: ## CM</h6>
                        <h6 className="big-blue">ANCHO: ## CM</h6>
                        <h6 className="big-blue">ALTO: ## CM</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="medidas">
              <Col sm={3} xs={4} className="d-flex flex-row align-items-center">
                <CheckboxFormsy value={false} name="rad" label="¿Recolección a domicilio?" labelClassName="bluep" />
              </Col>
              <Col sm={3} xs={6} className="d-flex flex-row align-items-center">
                <CheckboxFormsy value={false} name="ead" label="¿Entrega a domicilio?" labelClassName="bluep" />
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <Row>
              <Col sm={12} style={{ marginTop: 7 }}>
                {/* <InputFormsy label="Código Postal Origen" labelClassName="h6" name="cp-origen" /> */}
                <AutocompleteFormsy
                  label="Código Postal Origen"
                  labelClassName="h6"
                  name="cp-origen"
                  autocompleteOptions={postalCodeList}
                  onChange={this.handleOriginZipcode}
                />
              </Col>
              <Col sm={12} style={{ marginTop: 7 }}>
                {/* <InputFormsy label="Código Postal Destino" labelClassName="h6" name="cp-destino" /> */}
                <AutocompleteFormsy
                  label="Código Postal Destino"
                  labelClassName="h6"
                  name="cp-destino"
                  autocompleteOptions={postalCodeList}
                  onChange={this.handleDestinationZipcode}
                />
              </Col>
              <Col sm={9} xs={6}>
                <SelectFormsy
                  label="Tipo de paquete"
                  labelClassName="h6"
                  name="packageType"
                  selectProps={{
                    style: {
                      marginTop: 3,
                      marginBottom: 6
                    },
                    className: 'custom-select'
                  }}
                  value=""
                  required
                >
                  <option value="">Selecciona tipo</option>
                  {packageTypes.map(t => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </SelectFormsy>
              </Col>
              <Col sm={3} xs={6}>
                <InputFormsy
                  label="Peso(KG)"
                  labelClassName="h6"
                  name="weight"
                  value={1}
                  inputProps={{
                    type: 'number',
                    min: 1,
                    style: {
                      marginTop: 3,
                      marginBottom: 6
                    },
                    placeholder: '1'
                  }}
                  required
                />
              </Col>
              <Col sm={3} xs={3} style={{ zIndex: 999999999 }}>
                {/* <div className="wrapper4" style={{ marginTop: 40 }}>
                  <div className="tooltip4">
                    <Row>
                      <Col sm={12} className="text-center">
                        <h5 className="big-pink-op">Peso máximo</h5>
                        <h6 className="big-blue">HASTA ### KG</h6>
                      </Col>
                    </Row>
                  </div>
                </div> */}
              </Col>
            </Row>
            <br />
            <Row>
              <Go to="/terminos">
                <span> Términos y condiciones</span>
              </Go>
            </Row>
            <Row>
              <AButton type="submit" variant="big" disabled={!canSubmit}>
                Cotizar
              </AButton>
            </Row>
          </Col>
        </Row>
        <br />
      </Formsy>
    )
  }
}

export default ParcelQuotationForm
