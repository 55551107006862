import React, { Component } from 'react'
import resumenCaja from 'img/resumen-caja.jpg'
import { Container, Row, Col } from 'reactstrap'
import { AButton } from 'shared/components/common'
import { ParcelQuotationCostProps } from './types'
import { currency } from 'core/utils'

export class ParcelQuotationCost extends Component<ParcelQuotationCostProps> {
  render() {
    const { detail, onClear } = this.props
    return (
      <Container className="pad">
        <Row>
          <Col sm={6}>
            <img src={resumenCaja} className="img-responsive" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <p>*El importe estimado se confirmará al momento de la recepción del paquete por personal de All Abordo.</p>
          </Col>
          <Col sm={2}>
            <Row>
              <Col sm={12}>
                <p style={{ marginBottom: 8 }}>{detail.length}cm</p>
              </Col>
              <Col sm={12}>
                <p>{detail.height}cm</p>
              </Col>
              <Col sm={12}>
                <p style={{ marginTop: -12 }}>{detail.width}cm</p>
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <h3 className="big-blue">Resumen</h3>
            <Row>
              <Col sm={4}>
                <h5 className="big-gray">Origen</h5>
                <h6 className="big-blue">{detail.origin}</h6>
                <br />
                <h5 className="big-gray">Destino</h5>
                <h6 className="big-blue">{detail.destination}</h6>
                <br />
              </Col>
              <Col sm={8}>
                <Row>
                  <Col sm={6}>
                    <h5 className="big-gray">Tipo de paquete:</h5>
                    <p>{detail.packageType}</p>
                  </Col>
                  <Col sm={6}>
                    <h5 className="big-gray">Peso(KG):</h5>
                    <p>{detail.weight} KG</p>
                  </Col>
                  <Col sm={12}>
                    <h5 className="big-gray">Valor declarado:</h5>
                    <p>{currency.format(detail.value)}</p>
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <h4 className="big-gray">Total aproximado:</h4>
                <h3 className="big-blue">*{currency.format(detail.cost)}</h3>
              </Col>
            </Row>
            <br />
            <AButton variant="med" onClick={onClear}>
              Volver a cotizar
            </AButton>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ParcelQuotationCost
